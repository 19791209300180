<template>
  <v-container fluid>
    <v-row>
      <v-col
        md="6"
        cols="12"
      >
        <base-material-card
          color="primary"
          icon="mdi-account-group"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">Informações básicas</div>
          </template>
          <v-row class="mt-5">
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="Titulo"
              >
                <v-text-field
                  label="Titulo"
                  v-model="profileInfo.title"
                  outlined
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="Descrição"
              >
                <v-textarea
                  label="Descrição"
                  v-model="profileInfo.description"
                  outlined
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                min-width="100"
                style="float: right"
                class="mt-n5 mr-0"
                @click="save()"
                :loading="loading"
              >
                Salvar
              </v-btn>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
      <v-col
        md="6"
        cols="12"
      >
        <base-material-card
          color="primary"
          icon="mdi-format-list-bulleted"
          inline
          class="px-5 py-3"
          style="min-height: 600px"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">Páginas de Acesso</div>
          </template>
          <v-row>
            <v-col cols="12">
              <v-text-field
                id="routes-search"
                v-model="search"
                append-icon="mdi-magnify"
                class="ml-auto mt-4 break-search bs-perfil"
                label="Procurar"
                hide-details
                single-line
              />
              <v-divider class="mt-10" />
              <v-data-table
                :headers="headers"
                :items="routes"
                :search.sync="search"
                v-model="urlSelected"
                show-select
                item-key="name"
                :headerProps="{
                  sortByText: 'Ordenar Por'
                }"
                :sort-by="['name']"
                :loading="loading"
                :disable-pagination="true"
                :hide-footer="true"
                :hide-default-header="loading"
                :hide-default-footer="true"
                no-results-text="Nenhum registro correspondente encontrado"
                class="overflow-auto"
              />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PerfisUsuariosService from '@/services/PerfisUsuariosService';
import routes from '@/store/modules/routes';

export default {
  data: () => ({
    loading: false,
    search: '',
    profileInfo: {
      id: null,
      title: null,
      description: null,
      urls: []
    },
    urlSelected: [],
    routes: [],
    headers: [
      {
        text: 'Página',
        value: 'name'
      },
      {
        text: 'Url',
        value: 'url'
      }
    ]
  }),

  mounted() {
    if (this.profileId) {
      this.getPerfilUsuario(this.profileId);
    }
    this.getRoutes();
  },

  computed: {
    profileId() {
      return this.$route.params.id;
    }
  },

  methods: {
    getRoutes() {
      this.$router.options.routes.forEach((route) => {
        if (
          route.path === '/home' ||
          route.path === '/login' ||
          route.path === '/perfil' ||
          route.path === '*' ||
          route.path === '/'
        ) {
          return;
        }

        route.children.forEach((child) => {
          console.log(child.name);
          this.routes.push({
            name: child.name,
            url: `${route.path}/${child.path.replace(/\/:[^/]+$/, '')}`
          });
        });
      });
    },

    getPerfilUsuario(profileId) {
      this.loading = true;

      PerfisUsuariosService.getPerfilUsuarioById(profileId)
        .then(({ data }) => {
          const info = data.data[0];
          this.profileInfo = info;
          this.setUrlSelected(info.urls);
        })
        .catch(() =>
          this.$toast.error(
            'Erro ao recuperar os dados do perfil do usuário.',
            '',
            {
              position: 'topRight'
            }
          )
        )
        .finally(() => {
          this.loading = false;
        });
    },

    setUrlSelected(urls) {
      urls.forEach((url) => {
        console.log('url', url.route_name);
        this.urlSelected.push({
          name: url.route_name
        });
      });
    },

    save() {
      this.profileInfo.urls = [];

      this.urlSelected.forEach((url) => {
        this.profileInfo.urls.push(url.name);
      });

      const data = this.profileInfo;

      this.loading = true;

      PerfisUsuariosService.save(data)
        .then(() => {
          this.$toast.success('Perfil de Usuário salvo com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.push({
            name: routes.state.appRoutes['PERFIS_USUARIOS_LISTAR']
          });
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error('Erro ao salvar perfil de usuário.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped>
.overflow-auto {
  max-height: 400px;
  overflow-y: auto;
}
</style>
